<template>
  <div>
    <v-card>
      <v-row class="mx-2 pt-2">
        <v-col>
          <h3>Panduan pembuatan Workflow Automasi</h3>
          <span class="d-block text-subtitle-2 mt-1">Cara Membuat dan menggunakan Workflow Automasi</span>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <div class="px-4 pb-4">
        <v-stepper
          v-model="page"
          flat
          vertical
        >
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :key="`header-${index}`"
              :complete="page > step.id"
              :step="step.id"
              editable
            >
              {{ step.name }}
              <small v-if="step.optional">Opsional</small>
            </v-stepper-step>
            <v-stepper-content
              :key="`content-${index}`"
              :step="step.id"
            >
              <component
                :is="step.component"
                @next="page += 1"
                @prev="page -= 1"
              />
            </v-stepper-content>
          </template>
        </v-stepper>
      </div>
    </v-card>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import GuideStepOne from './guides/GuideStepOne.vue'
import GuideStepTwo from './guides/GuideStepTwo.vue'
import GuideStepThree from './guides/GuideStepThree.vue'
import GuideStepFour from './guides/GuideStepFour.vue'
import GuideStepFive from './guides/GuideStepFive.vue'
import GuideStepSix from './guides/GuideStepSix.vue'

export default {
  components: {
    GuideStepOne,
    GuideStepTwo,
    GuideStepThree,
    GuideStepFour,
    GuideStepFive,
    GuideStepSix,
  },
  setup() {
    const page = ref(1)
    const steps = [
      {
        id: 1,
        name: 'Menambah Token',
        component: 'GuideStepOne',
        optional: false,
      },
      {
        id: 2,
        name: 'Membuat Workflow Baru',
        component: 'GuideStepTwo',
        optional: false,
      },
      {
        id: 3,
        name: 'Menambah Trigger',
        component: 'GuideStepThree',
        optional: false,
      },
      {
        id: 4,
        name: 'Menambah Filter',
        component: 'GuideStepFour',
        optional: true,
      },
      {
        id: 5,
        name: 'Menambah Aksi',
        component: 'GuideStepFive',
        optional: false,
      },
      {
        id: 6,
        name: 'Selesai',
        component: 'GuideStepSix',
        optional: false,
      },
    ]

    return {
      page,
      steps,
    }
  },
}
</script>
