<template>
  <v-card
    class="mb-12"
    flat
    outlined
  >
    <v-card-title>
      Cara Menambah Aksi
    </v-card-title>
    <v-card-text>
      <v-img
        :src="require('@/assets/images/guides/guide_action.jpg')"
        alt="Cara menambah aksi"
        class="my-4"
      />
      <div class="text-subtitle-1 mb-1">
        1. Pada Form Workflow bagian Action, tekan tombol (+) di bagian kanan atas. Anda bisa menambahkan maksimal 3 aksi.
      </div>
      <div class="text-subtitle-1 mb-1">
        2. Pilih Objek yang ingin kalian gunakan sebagai aksi.
      </div>
      <div class="text-subtitle-1 mb-1">
        3. Pilih aksi yang ingin anda lakukan.
      </div>
      <div class="text-subtitle-1 mb-1">
        4. Pilih akun bot yang ingin anda gunakan.
      </div>
      <div class="text-subtitle-1 mb-1">
        5. Pilih Chat yang ingin anda gunakan sebagai tujuan pengiriman pesan.
        Jika tidak ada chat yang bisa dipilih, maka anda harus mengirimkan pesan terlebih dahulu ke bot tersebut
        melalui telegram menggunakan nomor yang anda inginkan.
        <b>Anda bisa menggunakan link 'https://t.me/{username_bot}' untuk mengirimkan pesan telegram ke bot tersebut.</b>
      </div>
      <div class="text-subtitle-1 mb-1">
        6. Tentukan isi dari pesan yang akan anda kirimkan. Anda bisa menggunakan variabel yang muncul saat anda meng-klik field ini.
        Pada contoh diatas, saya menggunakan variabel {email.from}, {email.subject}, dan {email.text}.
        <b>Variabel adalah sebuah nilai yang akan berubah - ubah sesuai dengan data dari trigger yang terjadi.</b>
      </div>
      <div class="text-subtitle-1 mb-1">
        7. Jika anda iungin menghapus aksi, tekan tombol (X) di bagian kana atas form aksi.
      </div>
      <div class="text-subtitle-1 mb-1">
        8. Pada contoh diatas, objek yang dipilih adalah telegram, aksinya 'Send Message', dan akun bot yang digunakan adalah ta_1_bot.
        Tujuan pengirimannya adalah akun 'Surya Bumantara'.
        <b>Ini artinya saat workflow dijalankan, maka akun telegram bot 'ta_1_bot' akan mengirimkan pesan ke akun 'Surya Bumantara'.</b>
      </div>
    </v-card-text>
    <guide-nav-button
      next
      prev
      @next="$emit('next')"
      @prev="$emit('prev')"
    />
  </v-card>
</template>
<script>
import GuideNavButton from '../GuideNavButton.vue'

export default {
  components: {
    GuideNavButton,
  },
  setup() {

  },
}
</script>
