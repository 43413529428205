<template>
  <v-card
    class="mb-12"
    flat
    outlined
  >
    <v-card-title>
      Cara Menambah Trigger
    </v-card-title>
    <v-card-text>
      <v-img
        :src="require('@/assets/images/guides/guide_trigger.jpg')"
        alt="Cara menambah trigger"
        class="my-4"
      />
      <div class="text-subtitle-1 mb-1">
        1. Pada Form Workflow bagian Trigger, pilih objek (platform) yang ingin kalian gunakan sebagai trigger
      </div>
      <div class="text-subtitle-1 mb-1">
        2. Pilih Aksi yang ingin kalian gunakan sebagai trigger
      </div>
      <div class="text-subtitle-1 mb-1">
        3. Pilih akun yang akan diawasi oleh workflow
      </div>
      <div class="text-subtitle-1 mb-1">
        4. Pada contoh diatas, Objek yang dipilih adalah Gmail,
        aksi yang dipilih adalah 'Email Received',
        dan akun yang dipilih adalah 'bumantara.surya2213@gmail.com'.
        <b>Ini artinya, setiap kali ada email baru pada inbox akun 'bumantara.surya2213@gmail.com', workflow ini akan dijalankan.</b>
      </div>
    </v-card-text>
    <guide-nav-button
      next
      prev
      @next="$emit('next')"
      @prev="$emit('prev')"
    />
  </v-card>
</template>
<script>
import GuideNavButton from '../GuideNavButton.vue'

export default {
  components: {
    GuideNavButton,
  },
  setup() {

  },
}
</script>
