import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VRow,{staticClass:"mx-2 pt-2"},[_c(VCol,[_c('h3',[_vm._v("Panduan pembuatan Workflow Automasi")]),_c('span',{staticClass:"d-block text-subtitle-2 mt-1"},[_vm._v("Cara Membuat dan menggunakan Workflow Automasi")])])],1),_c(VDivider,{staticClass:"my-4"}),_c('div',{staticClass:"px-4 pb-4"},[_c(VStepper,{attrs:{"flat":"","vertical":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._l((_vm.steps),function(step,index){return [_c(VStepperStep,{key:("header-" + index),attrs:{"complete":_vm.page > step.id,"step":step.id,"editable":""}},[_vm._v(" "+_vm._s(step.name)+" "),(step.optional)?_c('small',[_vm._v("Opsional")]):_vm._e()]),_c(VStepperContent,{key:("content-" + index),attrs:{"step":step.id}},[_c(step.component,{tag:"component",on:{"next":function($event){_vm.page += 1},"prev":function($event){_vm.page -= 1}}})],1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }