<template>
  <v-card
    class="mb-12"
    flat
    outlined
  >
    <v-card-title>
      Cara Menambah Workflow Baru
    </v-card-title>
    <v-card-text>
      <div class="text-subtitle-1 mb-1">
        1. Pergi ke
        <router-link
          :to="{name: 'integrations' }"
          target="_blank"
        >
          Halaman Workflow
        </router-link>
      </div>
      <div class="text-subtitle-1 mb-1">
        2. Klik Tombol 'Buat Workflow Baru' berwarna biru di bagian kanan atas halaman
      </div>
    </v-card-text>
    <guide-nav-button
      next
      prev
      @next="$emit('next')"
      @prev="$emit('prev')"
    />
  </v-card>
</template>
<script>
import GuideNavButton from '../GuideNavButton.vue'

export default {
  components: {
    GuideNavButton,
  },
  setup() {

  },
}
</script>
