<template>
  <v-card-actions>
    <v-btn
      v-if="next"
      color="primary"
      @click="$emit('next')"
    >
      Lanjutkan
    </v-btn>
    <v-btn
      v-if="prev"
      color="secondary"
      @click="$emit('prev')"
    >
      Kembali
    </v-btn>
  </v-card-actions>
</template>
<script>

export default {
  props: {
    next: {
      type: Boolean,
      default: false,
    },
    prev: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
  },
}
</script>
