<template>
  <v-card
    class="mb-12"
    flat
    outlined
  >
    <v-card-title>
      Selesai
    </v-card-title>
    <v-card-text>
      <div class="text-subtitle-1 mb-1">
        Selamat, anda telah berhasil membuat workflow automasi pertama anda.
        <b>
          Dengan ada nya workflow tersebut, sekarang setiap kali ada email yang masuk,
          maka bot anda akan otomatis mengirimkan pesan berisi informasi email tersebut ke akun telegram anda (jika memenuhi seluruh kondisi filter).
        </b>
        Silahkan mencoba membuat workflow racikan anda sendiri menggunakan ilmu yang sudah anda dapatkan melalui panduan ini.
      </div>
    </v-card-text>
    <guide-nav-button
      prev
      @next="$emit('next')"
      @prev="$emit('prev')"
    />
  </v-card>
</template>
<script>
import GuideNavButton from '../GuideNavButton.vue'

export default {
  components: {
    GuideNavButton,
  },
  setup() {

  },
}
</script>
