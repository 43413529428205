<template>
  <v-card
    class="mb-12"
    flat
    outlined
  >
    <v-card-title>
      Cara Menambah Filter
    </v-card-title>
    <v-card-text>
      <v-img
        :src="require('@/assets/images/guides/guide_filter.jpg')"
        alt="Cara menambah filter"
        class="my-4"
      />
      <div class="text-subtitle-1 mb-1">
        1. Pada Form Workflow bagian Filter, tekan tombol (+) di bagian kanan atas. Anda bisa menambah filter sebanyak mungkin.
      </div>
      <div class="text-subtitle-1 mb-1">
        2. Pilih Properti yang ingin kalian gunakan sebagai filter.
        pada contoh diatas, properti yang dipilih adalah 'From' (dari)
      </div>
      <div class="text-subtitle-1 mb-1">
        3. Setelah anda memilih properti,komponen nomor 3, 4 & 5 akan muncul.
        pada komponen nomor 3, pilih operator yang ingin anda gunakan untuk membandingkan properti.
        pada contoh diatas, operator yang dipilih adalah '==' atau sama dengan.
      </div>
      <div class="text-subtitle-1 mb-1">
        4. Pilih nilai yang ingin anda gunakan sebagai pembanding.
        pada contoh diatas, nilai yang dipilih adalah 'oriens@gmail.com'.
      </div>
      <div class="text-subtitle-1 mb-1">
        5. Untuk menghapus filter, gunakan tombol (X) di bagian samping setiap filter'.
      </div>
      <div class="text-subtitle-1 mb-1">
        5. Pada contoh diatas, filter yang dibuat adalah 'From' == 'oriens@gmail.com' & 'Text' includes 'penting'.
        <b>Ini artinya workflow hanya akan dijalankan ketika
          email yang masuk dikirim oleh 'oriens@gmail.com' &
          text (body) email mengandung kata 'penting'
        </b>
      </div>
    </v-card-text>
    <guide-nav-button
      next
      prev
      @next="$emit('next')"
      @prev="$emit('prev')"
    />
  </v-card>
</template>
<script>
import GuideNavButton from '../GuideNavButton.vue'

export default {
  components: {
    GuideNavButton,
  },
  setup() {

  },
}
</script>
